
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useRef } from "react";
import { useState } from "react";
import { Alldata, Approve, GetReward, ReferralOuter, Stake } from "../hooks/useContractData";
import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { shortAddress, toLocale } from "../hooks/useContract";
import { toast } from "react-hot-toast"
import { CircularProgress } from "@material-ui/core";
import { PoolUSDT180, PoolUSDT360, PoolZEB180, PoolZEB360, PoolZEB90, poolDetails } from '../config';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;


    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}
export default function ModelPOP({ props, onClose }) {

    const { referedBy, canRefer } = ReferralOuter();


    const selectRef = useRef(null);
    const select2Ref = useRef(null);
    const location = useLocation()

    const [Staked, setStaked] = useState(false);
    const [amount, setAmount] = useState("");
    const [pool, setPool] = useState(PoolZEB90);
    const [refer, setrefer] = useState("");
    const [load, setLoad] = useState(true);
    const [day, setDay] = useState(0);
    const [percent, setPercent] = useState(0);
    const [coin, setCoin] = useState();

    // Export Functions
    const { execute: getRewardData, Data } = GetReward()
    const { execute: ApproveToken } = Approve(pool);
    const { execute: Staking } = Stake(pool);
    const { allowance, HfgBalance } = Alldata(pool);
    const [newStake, setNewStake] = useState(props);
    const [value, setValue] = useState(PoolZEB90);

    const RewardCallback = useCallback(async () => {
        const selectedPool = value
        const amount = select2Ref.current?.value;
        try {
            setLoad(false)
            const promist = await getRewardData?.(String(amount), selectedPool);
            setLoad(true)

        } catch (err) {
            setLoad(true)
            toast.error(
                err.reason ? err.reason : err.data ? err.data.message : err.message
            );
        }
    }, [getRewardData, value]);


    const StakeCallback = useCallback(async () => {
        try {
            const amount = select2Ref.current?.value;
            if (amount > 0) {
                setStaked(true);
                const promist = await Staking?.(String(amount), String(refer));
                setStaked(false);
                onClose()
                setAmount("")
            }
            else {
                toast.error("Please fill up amount")
            }
        } catch (err) {
            setStaked(false);
            onClose()
            toast.error(
                err.reason ? err.reason : err.data ? err.data.message : err.message
            );
        }
    }, [refer, Staking, onClose]);


    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const ReferralId = queryParams.get('referrer');
        if (canRefer === true && referedBy !== 0) {
            setrefer(referedBy)
        } else {
            setrefer(ReferralId)
        }
    }, [location.search, canRefer, referedBy])



    useEffect(() => {
        setNewStake(props)
    }, [props])


    const handleChange = (event, newValue) => {
        setValue(newValue);
        setPool(newValue)

    };

    const handleData = (value) => {
        const poolData = poolDetails.find(item => item.pool.toLowerCase() === value.toLowerCase())
        setDay(poolData.days)
        setPercent(poolData.percent)
        setCoin(poolData.coin)
    };

    useEffect(() => {
        handleData(value)
    }, [value])


    return (<>
        <BootstrapDialog
            onClose={onClose}
            aria-labelledby="customized-dialog-title"
            open={newStake}
        >
            <BootstrapDialogTitle
                className="zebclosewrap flex justify-content-between align-items-center"
                id="customized-dialog-title"
                onClose={onClose}
                sx={{ backgroundColor: "#000000d6", color: "#fff" }} >
                <h3 className="mb-0">Stake {coin}</h3>
            </BootstrapDialogTitle>
            <DialogContent
                dividers
                sx={{ backgroundColor: "#000000d6", height: 'auto' }}
            >
                <div className="stakebtnarea">
                    <h3 className="Zebheading text-white">
                        Participate Zeb Stake
                    </h3>
                    <p className="fs-6 mt-2 m-auto" style={{ width: "fit-content", border: "1px solid #ffd600", padding: "8px", borderRadius: "5px" }}>Referral Wallet - {refer !== null ? shortAddress(refer) : "You do not have a referrer"}</p>

                    <div className="tabbar flex">
                        <Tabs
                            value={value}
                            ref={selectRef}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons="auto"
                            allowScrollButtonsMobile="mobile"
                        >
                            <Tab label="ZEB-90" value={PoolZEB90} />
                            <Tab label="ZEB-180" value={PoolZEB180} />
                            <Tab label="ZEB-360" value={PoolZEB360} />
                            <Tab label="USDT-180" value={PoolUSDT180} />
                            <Tab label="USDT-360" value={PoolUSDT360} />
                        </Tabs>

                    </div>
                    <div className="inputvalue">
                        <div className="leftwrap">
                            <div className="leftinfo">
                                <span className="labeltag">Aval Balance:</span>
                                <span className="labelspan">
                                    <div style={{ marginRight: "4px" }}>{toLocale(HfgBalance)} {coin}</div>
                                </span>
                            </div>

                            <div className="leftinfo">
                                <span className="labeltag">Total Days:</span>
                                <span>
                                    <div style={{ marginRight: "4px" }}>
                                        {day} DAYS
                                    </div>
                                </span>
                            </div>
                            <div className="leftinfo">
                                <span className="labeltag">Total Reward:</span>
                                <span className="d-flex align-items-center"> {load ?
                                    <>
                                        {toLocale(Data)} {coin}
                                    </>
                                    : <CircularProgress size={"16px"} />} </span>
                            </div>
                            <div className="leftinfo">
                                <span className="labeltag">Per Day Rewards:</span>
                                <span className="d-flex align-items-center">
                                    {load ? (Data > 0 && day > 0 ? toLocale(Data / day) : 0) : <CircularProgress size={"16px"} />}
                                </span>
                            </div>
                        </div>
                        <div className="rightwrap">
                            <div className="rightwrap">
                                <div className="rightinfo">
                                    <span className="labelapr">APR</span>
                                </div>
                                <div className="rightinfo">
                                    <span className="labelpercent">{percent}%</span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="inputbox">
                    <div className="inputwrap">
                        <input className="value"
                            ref={select2Ref}
                            type="number"
                            placeholder="Enter Amount"
                            value={amount}
                            onChange={(e) => {
                                setAmount(e.target.value)
                                RewardCallback()
                            }}
                            onKeyDown={(e) => {
                                const allowedKeys = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "Backspace", "ArrowLeft", "ArrowRight", "Delete", "."];
                                if (!allowedKeys.includes(e.key)) {
                                    e.preventDefault();
                                }
                            }}
                        />
                        <span className="maxbtn" onClick={() => setAmount(HfgBalance)}>Max</span>
                    </div>
                    {(allowance > 0 && (
                        <>
                            <button className="boxbtn" onClick={StakeCallback} disabled={Staked}>
                                {Staked ? "Staking..." : `Stake ${coin}`}
                            </button>
                        </>
                    )) || (
                            <button className="boxbtn" onClick={ApproveToken}>
                                Approve {coin}
                                <span></span>
                            </button>
                        )}
                </div>

                <br />
                <br />
                <p className=" notearea ">
                    *<b>Note</b> - If you unstake ZEB or USDT before maturity, 10% of
                    your staked amount will be deducted. Any pending rewards at
                    that time will not be processed. Ensure that you wait until
                    maturity to avoid these penalties.
                </p>

            </DialogContent>
        </BootstrapDialog>

    </>)
}