import React from "react";
import {
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
} from "@coreui/react";
const Faq6 = () => {
  return (
    <>
      <section className="faq">
        <div className="container">
          <h2 className="title-box">F.A.Q.</h2>
          <CAccordion flush>
            <CAccordionItem itemKey={1}>
              <CAccordionHeader>
                Are there withdrawal fees for unstaking Zeb Tokens?
              </CAccordionHeader>
              <CAccordionBody>
                No, there are no withdrawal fees when you decide to unstake your
                Zeb Tokens.
              </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem itemKey={2}>
              <CAccordionHeader>
                {" "}
                What types of staking pools are available?
              </CAccordionHeader>
              <CAccordionBody>
                We offer various types of staking pools, each with different
                time commitments and reward rates. This allows you to choose a
                pool that best suits your investment strategy.
              </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem itemKey={3}>
              <CAccordionHeader>
                Is there a penalty for early withdrawal?
              </CAccordionHeader>
              <CAccordionBody>
                Yes, if you withdraw your staked Zeb Tokens before the maturity
                of staking period, a 10% penalty will be imposed on your staked
                amount.
              </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem itemKey={4}>
              <CAccordionHeader>
                Can I earn from referring others to the staking platform?
              </CAccordionHeader>
              <CAccordionBody>
                Absolutely! We offer a 3-level referral program. The 1st level
                earns you 1% per month, the 2nd level earns you 0.35% per month,
                and the 3rd level earns you 0.15% per month on the staked amount
                of your referrals.
              </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem itemKey={5}>
              <CAccordionHeader>
                {" "}
                What is the maximum locking period of the staked amount?
              </CAccordionHeader>
              <CAccordionBody>
                After the maturity of your staked tokens, they will be released
                within a maximum of 3 days.
              </CAccordionBody>
            </CAccordionItem>
          </CAccordion>
        </div>
      </section>
    </>
  );
};

export default Faq6;
