import React from 'react'
import { FaTwitterSquare, FaFacebook, FaReddit, FaYoutube, FaTelegram, FaMedium ,FaDiscord, FaInstagram} from 'react-icons/fa';
import { Link } from 'react-router-dom';
const Footer = () => {
    return (
        // footer area start
        <footer className="footer_widgets">
            <div className="footer_bottom">
                <div className="container">
                    <div className="row justify-content-center justify-content-lg-end">
                        <div className="col-10 col-xxl-12">
                            <div className="footer_bottom_inner d-flex justify-content-between align-items-center">
                                <div className="copyright_right">
                                    <p>© 2023 <i className="icofont-heart"></i>  by <a href="/">Zeb Money</a></p>
                                </div>
                                <div className="footer_bottom_link_menu">
                                    <div className="footer_social">
                                        <ul className="d-flex">
                                            <li><Link to="https://www.instagram.com/zebmoneyofficial/" title='Instagram' target="_blank" className="mx-2"><FaInstagram /></Link></li>
                                            <li><Link to="https://twitter.com/Zeb_money" title='Twitter' target="_blank" className="mx-2"><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg></Link></li>
                                            <li><Link to="https://www.youtube.com/channel/UC1Cxqn82bW9r0q5mzBCtAJQ" title='Youtube' target="_blank" className="mx-2"><FaYoutube /></Link></li>
                                            <li><Link to="https://medium.com/@zebmoney" title='Medium ' target="_blank" className="mx-2"><FaMedium /></Link></li>
                                            {/* <li><Link to="https://www.reddit.com/r/zebmoney/" title='Reddit ' target="_blank" className="mx-2"><FaReddit /></Link></li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer