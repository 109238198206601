import { useLocation } from "react-router-dom";
import { useState, useEffect, useMemo, useCallback } from 'react';
import { useAccount } from "wagmi";

export function useQueryAndSetAddress() {
    const { address: defaultAddress } = useAccount();
    const [address, setAddress] = useState(defaultAddress);
    const query = new URLSearchParams(useLocation().search);
    const urlAddress = query.get("user");

    useEffect(() => {
        if (urlAddress && address !== urlAddress) {
        
            setAddress(urlAddress);
        } else if (urlAddress === null) {
            setAddress(defaultAddress);
        }
    }, [urlAddress, address, defaultAddress]);


    const disconnectAddress = useCallback(() => {
        setAddress(undefined);
        window.location.reload();
    }, []);


    return useMemo(() => {
        return {
            address,
            disconnectAddress,
        };
    }, [address, disconnectAddress]);
}
