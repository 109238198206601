import React from "react";
import { AdminFunction, ContractBalance } from "../hooks/useContractData";
import { toLocale } from "../hooks/useContract";
import { AdminGraph } from "../hooks/adminGraph";
import { CoinAddress, PoolUSDT180, PoolUSDT360, PoolZEB180, PoolZEB360, PoolZEB90, poolDetails } from "../config";
import { formatUnits } from "viem";
import { CalculateReward } from "../config/helper";

const LiquidityForm = ({ balance, poolAddress, actionLabel, actionFunction }) => (
    <div className="os_row align-items-center boxpart">
        <div className="leftbx">
            <strong style={{ display: "flex", flexWrap: "wrap" }}>
                {actionLabel} <span className="d-block" style={{ color: "#f6b233", width: "160px" }}>(Bal: {toLocale(balance)} ZEB)</span>
            </strong>
        </div>
        <div className="inputbox">
            <form onSubmit={(e) => {
                e.preventDefault();
                actionFunction(CoinAddress, e.target.amount.value, poolAddress);
            }}>
                <input className="inputswap" type="number" name="amount" autoComplete="off" placeholder="Enter Amount" />
                <button type="submit">Submit</button>
            </form>
        </div>
    </div>
);

const AdminPanel = () => {
    const { zeb90, zeb180, zeb360, usdt180, usdt360 } = ContractBalance()
    const { AddLiquidity, EmergencyWithdraw } = AdminFunction()
    const { totalStaked, referralData } = AdminGraph()

    // This will be an array of formatted StakeAmounts, indexed by the pool address
    const formattedStakeAmounts = {};

    poolDetails.forEach(detail => {
        const poolData = totalStaked.find(item => item.poolAddress.toLowerCase() === detail.pool.toLowerCase());

        // Get the decimals for the stakeToken associated with this poolData
        const decimals = poolData?.StakeToken.toLowerCase() === CoinAddress.toLowerCase() ? 18 : 6;  // default to 18 if not found

        formattedStakeAmounts[detail.pool] = formatUnits(poolData?.StakeAmount || 0, decimals);
    });


    function calculateDailyReward(pool, stakeAmount) {
        // the monthly percentages for each level
        const levelPercentages = {
            1: 0.01,    // 1%
            2: 0.0035, // 0.35%
            3: 0.0015  // 0.15%
        };

        // Calculate daily reward
        const monthlyPercentage = levelPercentages[Number(pool.level)];
        const monthlyReward = stakeAmount * monthlyPercentage;
        const dailyReward = monthlyReward / 30; // 30 days
        return dailyReward;
    }

    const rewardsByAddress = {};

    for (let pool of referralData) {
        const dailyRewardForPool = calculateDailyReward(pool, formatUnits(pool.stakeAmount, pool?.stakeToken.toLowerCase() === CoinAddress.toLowerCase() ? 18 : 6));
        if (rewardsByAddress[pool.poolAddress.toLowerCase()]) {
            rewardsByAddress[pool.poolAddress.toLowerCase()] += dailyRewardForPool;
        } else {
            rewardsByAddress[pool.poolAddress.toLowerCase()] = dailyRewardForPool;
        }
    }

    return (
        <>
            <section className="mainbox adminform">
                <div className="os_container">
                    <div className="boxmain">
                        <div className="boxinner">

                            <LiquidityForm balance={zeb90} poolAddress={PoolZEB90} actionLabel="Add ZEB-90 Liquidity" actionFunction={AddLiquidity} />
                            <LiquidityForm balance={zeb90} poolAddress={PoolZEB90} actionLabel="ZEB-90 Withdraw" actionFunction={EmergencyWithdraw} />
                            <div className="leftbx mt-4">
                                <strong className="d-flex flex-wrap"> Total Staked: <span className="d-block " style={{ color: "#f6b233", width: "auto" }}> &nbsp;{formattedStakeAmounts[PoolZEB90]} ZEB</span></strong>
                            </div>
                            <div className="leftbx mt-2">
                                <strong className="d-flex flex-wrap">Staked reward per day: <span className="d-block" style={{ color: "#f6b233", width: "auto" }}> &nbsp;{toLocale(CalculateReward(formattedStakeAmounts[PoolZEB90], 90) / 90)} ZEB</span></strong>
                            </div>
                            <div className="leftbx mt-2">
                                <strong className="d-flex flex-wrap">Referral Reward per day: <span className="d-block" style={{ color: "#f6b233", width: "auto" }}> &nbsp;{toLocale(rewardsByAddress[PoolZEB90.toLowerCase()])} ZEB</span></strong>
                            </div>
                            <div className="leftbx mt-2">
                                <strong className="d-flex flex-wrap">Total Reward per day ≈ <span className="d-block" style={{ color: "#f6b233", width: "auto" }}> &nbsp;{toLocale(rewardsByAddress[PoolZEB90.toLowerCase()] + (CalculateReward(formattedStakeAmounts[PoolZEB90], 90) / 90))} ZEB</span></strong>
                            </div>


                        </div>
                        <div className="boxinner">
                            <LiquidityForm balance={zeb180} poolAddress={PoolZEB180} actionLabel="Add ZEB-180 Liquidity" actionFunction={AddLiquidity} />
                            <LiquidityForm balance={zeb180} poolAddress={PoolZEB180} actionLabel="ZEB-180 Withdraw" actionFunction={EmergencyWithdraw} />
                            <div className="leftbx mt-4">
                                <strong className="d-flex flex-wrap"> Total Staked: <span style={{ color: "#f6b233", width: "auto" }}>&nbsp;{formattedStakeAmounts[PoolZEB180]} ZEB</span></strong>
                            </div>
                            <div className="leftbx mt-2">
                                <strong className="d-flex flex-wrap">Staked reward per day: <span style={{ color: "#f6b233", width: "auto" }}>&nbsp;{toLocale(CalculateReward(formattedStakeAmounts[PoolZEB180], 180) / 180)} ZEB</span></strong>

                            </div>
                            <div className="leftbx mt-2">
                                <strong className="d-flex flex-wrap">Referral Reward per day: <span style={{ color: "#f6b233", width: "auto" }}>&nbsp;{toLocale(rewardsByAddress[PoolZEB180.toLowerCase()])} ZEB</span></strong>
                            </div>
                            <div className="leftbx mt-2">
                                <strong className="d-flex flex-wrap">Total Reward per day ≈ <span style={{ color: "#f6b233", width: "auto" }}>&nbsp;{toLocale(rewardsByAddress[PoolZEB180.toLowerCase()] + (CalculateReward(formattedStakeAmounts[PoolZEB180], 180) / 180))} ZEB</span></strong>
                            </div>
                        </div>

                        <div className="boxinner">

                            <LiquidityForm balance={zeb360} poolAddress={PoolZEB360} actionLabel="Add ZEB-360 Liquidity" actionFunction={AddLiquidity} />
                            <LiquidityForm balance={zeb360} poolAddress={PoolZEB360} actionLabel="ZEB-360 Withdraw" actionFunction={EmergencyWithdraw} />
                            <div className="leftbx mt-4">
                                <strong className="d-flex flex-wrap"> Total Staked: <span style={{ color: "#f6b233", width: "auto" }}>&nbsp;{formattedStakeAmounts[PoolZEB360]} ZEB</span></strong>
                            </div>
                            <div className="leftbx mt-2">
                                <strong className="d-flex flex-wrap">Staked reward per day: <span style={{ color: "#f6b233", width: "auto" }}>&nbsp;{toLocale(CalculateReward(formattedStakeAmounts[PoolZEB360], 360) / 360)} ZEB</span></strong>

                            </div>

                            <div className="leftbx mt-2">
                                <strong className="d-flex flex-wrap">Referral Reward per day: <span style={{ color: "#f6b233", width: "auto" }}>&nbsp;{toLocale(rewardsByAddress[PoolZEB360.toLowerCase()])} ZEB</span></strong>
                            </div>
                            <div className="leftbx mt-2">
                                <strong className="d-flex flex-wrap">Total Reward per day ≈ <span style={{ color: "#f6b233", width: "auto" }}>&nbsp;{toLocale(rewardsByAddress[PoolZEB360.toLowerCase()] + (CalculateReward(formattedStakeAmounts[PoolZEB360], 360) / 360))} ZEB</span></strong>
                            </div>
                        </div>
                        <div className="boxinner">

                            <LiquidityForm balance={usdt180} poolAddress={PoolUSDT180} actionLabel="Add USDT-180 Liquidity" actionFunction={AddLiquidity} />
                            <LiquidityForm balance={usdt180} poolAddress={PoolUSDT180} actionLabel="USDT-180 Withdraw" actionFunction={EmergencyWithdraw} />
                            <div className="leftbx mt-4">
                                <strong className="d-flex flex-wrap"> Total Staked: <span style={{ color: "#f6b233", width: "auto" }}>&nbsp;{formattedStakeAmounts[PoolUSDT180]} USDT</span></strong>
                            </div>
                            <div className="leftbx mt-2">
                                <strong className="d-flex flex-wrap">Staked reward per day: <span style={{ color: "#f6b233", width: "auto" }}>&nbsp;{toLocale(CalculateReward(formattedStakeAmounts[PoolUSDT180], 180) / 180)} USDT</span></strong>

                            </div>
                            <div className="leftbx mt-2">
                                <strong className="d-flex flex-wrap">Referral Reward per day: <span style={{ color: "#f6b233", width: "auto" }}>&nbsp;{toLocale(rewardsByAddress[PoolUSDT180.toLowerCase()])} USDT</span></strong>
                            </div>
                            <div className="leftbx mt-2">
                                <strong className="d-flex flex-wrap">Total Reward per day ≈ <span style={{ color: "#f6b233", width: "auto" }}>&nbsp;{toLocale(rewardsByAddress[PoolUSDT180.toLowerCase()] + (CalculateReward(formattedStakeAmounts[PoolUSDT180], 180) / 180))} ZEB</span></strong>

                            </div>
                        </div>
                        <div className="boxinner">

                            <LiquidityForm balance={usdt360} poolAddress={PoolUSDT360} actionLabel="Add USDT-360 Liquidity" actionFunction={AddLiquidity} />
                            <LiquidityForm balance={usdt360} poolAddress={PoolUSDT360} actionLabel="USDT-360 Withdraw" actionFunction={EmergencyWithdraw} />
                            <div className="leftbx mt-4">
                                <strong className="d-flex flex-wrap"> Total Staked: <span style={{ color: "#f6b233", width: "auto" }}>&nbsp;{formattedStakeAmounts[PoolUSDT360]} USDT</span></strong>
                            </div>
                            <div className="leftbx mt-2">
                                <strong className="d-flex flex-wrap">Staked reward per day: <span style={{ color: "#f6b233", width: "auto" }}>&nbsp;{toLocale(CalculateReward(formattedStakeAmounts[PoolUSDT360], 360) / 360)} USDT</span></strong>

                            </div>
                            <div className="leftbx mt-2">
                                <strong className="d-flex flex-wrap">Referral Reward per day: <span style={{ color: "#f6b233", width: "auto" }}>&nbsp;{toLocale(rewardsByAddress[PoolUSDT360.toLowerCase()])} USDT</span></strong>
                            </div>
                            <div className="leftbx mt-2">
                                <strong className="d-flex flex-wrap">Total Reward per day ≈ <span style={{ color: "#f6b233", width: "auto" }}>&nbsp;{toLocale(rewardsByAddress[PoolUSDT360.toLowerCase()] + (CalculateReward(formattedStakeAmounts[PoolUSDT360], 360) / 360))} ZEB</span></strong>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AdminPanel;
