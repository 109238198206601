import React, { useEffect, useRef, useState } from "react";
import {
  CButton,
  CHeader,
  CHeaderBrand,
  CContainer,
} from "@coreui/react";
import logo from "../Assets/images/logo.webp";
import LazyLoad from "react-lazyload";
import { useAccount } from "wagmi";
import { useConnect, useDisconnect, useNetwork, useSwitchNetwork } from 'wagmi'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { defaultNetwork } from "../config";
import { shortAddress } from "../hooks/useContract";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { toast } from "react-hot-toast"
import { Power3, gsap } from "gsap";
import anime from 'animejs';
import CopyToClipboard from 'react-copy-to-clipboard';
import {  useQueryAndSetAddress } from "../hooks/userAddress";




const Header = () => {
  const headerelement = useRef(null);
  // const { address } = useAccount()
  const {address,disconnectAddress} = useQueryAndSetAddress()
  


  const { disconnect } = useDisconnect()
  const { chain } = useNetwork()

  useEffect(() => {
    if (window.innerWidth > 992) {
      const tl = gsap.timeline({
        defaults: { duration: 1, ease: Power3.easeOut },
      });
      tl.from("#demo3", { autoAlpha: 0 })
        .from(".headerbrand", { duration: 0.8, y: "-100%", opacity: 0, ease: "power1.out", })
    }


  }, []);
  // Loader
  const [counter, setCounter] = useState(0);
  const [isPageLoaded, setIsPageLoaded] = useState(true);

  useEffect(() => {
    window.onload = () => {
      setIsPageLoaded(true);
    };
  }, []);

  useEffect(() => {
    if (isPageLoaded) {
      const tl = anime.timeline();
      tl.add({
        targets: ['.loaderx', '.loader__layer', '.loader__container'],
        duration: 700,
        visibility: 'hidden',
        translateY: '-100%',
        easing: 'easeInExpo',
        delay: 300,
        complete: function (anim) {
          anime.remove('.loaderx, .loader__layer, .loader__container'); // Remove these elements from the anime.js instance.
        }
      });
    } else {
      // Reset animation state here
      const elements = document.querySelectorAll('.loaderx, .loader__layer, .loader__container');
      elements.forEach(el => {
        el.style.visibility = 'visible';
        el.style.transform = 'translateY(0)';
      });
    }
  }, [isPageLoaded]);

  // Still use setInterval for updating the counter.
  useEffect(() => {
    const loaderTimer = setInterval(() => {
      setCounter((prevCounter) => {
        if (prevCounter < 100) {
          const newCounter = prevCounter + 1;
          anime({
            targets: '.loader__layer',
            duration: 0,
            width: `${newCounter}%`,
          });
          return newCounter;
        } else {
          return prevCounter;
        }
      });
    }, 35);
    // Clear the interval if the component unmounts.
    return () => {
      clearInterval(loaderTimer);
    };
  }, []);



  const { connect } = useConnect({
    connector: new InjectedConnector(),
  })

  const { switchNetworkAsync } = useSwitchNetwork({
    chainId: defaultNetwork
  })

  async function disconnectwallet() {
    disconnectAddress()
    localStorage.removeItem('wagmi.injected.shimDisconnect')
    window.localStorage.clear()
    window.dispatchEvent(new Event("storage"));
  }

  const handleConnectWalletClick = () => {
    connect();
    localStorage.setItem('wagmi.injected.shimDisconnect', true);
    if (!window.ethereum) {
      toast.error('No wallet detected!');
    }
  };

  const notify = () => toast.success("Copy Successfully")

  return (
    <>
      <div className="loaderx">
        <div className="loader__container">
          <h1 className="loader__container__counter" aria-label="Loading Counter">{`${counter}%`}</h1>
          <h2 className="loader__container__loading" aria-label="Loading">loading...</h2>
        </div>
        <div className="loader__layer" aria-hidden="false"></div>
      </div>
      <header ref={headerelement}>
        <CHeader className="headerbrand">
          <CContainer>
            <div className="d-flex justify-content-between align-items-center" style={{ width: "100%" }}>
              <CHeaderBrand to="/">
                {" "}
                <div className="header_logo">
                  <a className="sticky_none" href="/">
                    <LazyLoad
                      className="logoMainHead"
                      once
                      placeholder={
                        <svg
                          className="loaderAnim"
                          version="1.1"
                          id="L9"
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          viewBox="0 0 100 100"
                          enable-background="new 0 0 0 0"
                        >
                          <path
                            fill="#ffffff"
                            d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                          >
                            <animateTransform
                              attributeName="transform"
                              attributeType="XML"
                              type="rotate"
                              dur="1s"
                              from="0 50 50"
                              to="360 50 50"
                              repeatCount="indefinite"
                            />
                          </path>
                        </svg>
                      }
                    >
                      <img src={logo} />
                    </LazyLoad>
                  </a>
                </div>
              </CHeaderBrand>
              <div className="d-flex align-items-center " >
                <div>

                </div>
                {address &&
                  <>
                    {chain?.id !== defaultNetwork &&
                      <li className="wnbtn" onClick={() => {
                        switchNetworkAsync?.().then(() => { }).catch(() => { })
                      }} >
                        Wrong Network
                      </li>}
                  </>
                }

                {!address && (
                  <>

                    <button className="boxbtn2" onClick={() => {
                      handleConnectWalletClick()
                    }} >
                      Connect

                    </button>
                  </>
                )}
                {address && (
                  <div className="flex">
                    <CopyToClipboard text={address}>

                      <CButton className="btnMU" onClick={notify} >
                        <span>{shortAddress(address)}</span>
                      </CButton>
                    </CopyToClipboard>
                    <CButton className="btnMU" onClick={() => {
                      disconnectwallet()
                      disconnect()
                    }} style={{ marginLeft: '8px' }}>
                      <span className="d-none d-sm-block">Disconnect</span>
                      <ExitToAppIcon />
                    </CButton>
                  </div>
                )}
              </div>
            </div>
            {address &&
              <>
                {chain?.id !== defaultNetwork &&
                  <li className="mobilebtn" onClick={() => {
                    switchNetworkAsync?.().then(() => { }).catch(() => { })
                  }} >
                    Wrong Network
                  </li>}
              </>
            }
          </CContainer>
        </CHeader>
      </header>
    </>
  );
};

export default Header;
