import { toLocale } from "../hooks/useContract";

function dateCon(params) {
    const timestamp = Number(params) * 1000;
    const date = new Date(timestamp);
    const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    return formattedDate

}

 function maturityDate(params, daysToAdd) {
    const timestamp = Number(params) * 1000;
    const date = new Date(timestamp);
    
    // Add the specified number of days
    date.setDate(date.getDate() + daysToAdd);

    const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    
    return formattedDate;
}

const CalculateReward = (amount,days)=>{
    const RewardPercentage = [25,30,36]

    let reward = 0;
    if(days === 90){
        reward = amount * (RewardPercentage[0]/100)
        reward = reward*90/360
    }else if(days === 180){
        reward = amount * (RewardPercentage[1]/100)
        reward = reward*180/360
    }else if(days === 360){
        reward = amount * (RewardPercentage[2]/100)
    }
    return toLocale(reward);
}



export {
    dateCon,
    CalculateReward,
    maturityDate
}
