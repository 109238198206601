import { CRow, CCol } from '@coreui/react';
import { useGlobalData } from '../hooks/globalData';
import { CoinAddress, USDT } from '../config';
import { formatUnits } from 'viem';
import { toLocale } from '../hooks/useContract';



export const GlobalData = () => {
    const { totalStaked, data } = useGlobalData()

    const calculateTotal = (array, tokenAddress, fieldName) => {

        const matchedItems = array.filter(item => item.StakeToken.toLowerCase() === tokenAddress.toLowerCase());
      
        const stakedAmounts = matchedItems.map(item => Number(formatUnits(item[fieldName], CoinAddress === tokenAddress?18:6)));
      
        const totalStakedAmount = stakedAmounts.reduce((acc, curr) => acc + curr, 0);
      
        return totalStakedAmount;
      };
    

    const totalZebStake = calculateTotal(totalStaked, CoinAddress, 'StakeAmount');
    const totalUsdtStake = calculateTotal(totalStaked, USDT, 'StakeAmount');
    const totalZebClaim = calculateTotal(data, CoinAddress, 'ClaimAmount');
    const totalUsdtClaim = calculateTotal(data, USDT, 'ClaimAmount');

    return (

        <CCol md="12">
            <CRow xs={{ cols: 1 }} md={{ cols: 2 }} lg={{ cols: 4 }} className="justify-content-center">
                <CCol className='mb-2'>
                    <div className='inrInfoBx d-block '>
                        <div className="refbox">
                            <p>Total ZEB Staked</p>
                            <strong>{toLocale(totalZebStake)}
                            </strong>

                        </div>
                    </div>
                </CCol>
                <CCol className='mb-2'>
                    <div className='inrInfoBx d-block'>
                        <div className="refbox">
                            <p>Total ZEB Claimed</p>
                            <strong>
                                {toLocale(totalZebClaim)}
                            </strong>

                        </div>
                    </div>
                </CCol>
                <CCol className='mb-2'>
                    <div className='inrInfoBx d-block'>
                        <div className="refbox">
                            <p>Total USDT Staked</p>
                            <strong>
                                {toLocale(totalUsdtStake)}
                            </strong>

                        </div>
                    </div>
                </CCol>
                <CCol className='mb-2'>
                    <div className='inrInfoBx d-block'>
                        <div className="refbox">
                            <p>Total USDT Claimed</p>
                            <strong>
                                {toLocale(totalUsdtClaim)}
                            </strong>

                        </div>
                    </div>
                </CCol>
            </CRow>
        </CCol>
    )
}