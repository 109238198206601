import { CContainer, CButton, CRow, CCol } from "@coreui/react";
import LazyLoad from "react-lazyload";
import { Statistics } from "./Statistics";
import nftLvl7 from "../Assets/images/banner.gif";
import Referral from "./Referral";
import { CoinAddress, USDT, polygonchainRPC } from "../config";
import { addToken } from "../hooks/addToken";
import { toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";
import { useEffect, useRef } from "react";
import gsap from "gsap";
import { Power3 } from "gsap";
import { useQueryAndSetAddress } from "../hooks/userAddress";
import { GlobalData } from "./globalStatistics";

const Banner = () => {
  // const { address } = useAccount();
  const { address } = useQueryAndSetAddress()
  const navigate = useNavigate();
  const demoelement = useRef(null);

  useEffect(() => {
    // Check if the screen width is greater than a certain breakpoint (e.g., 768px for desktop)
    if (window.innerWidth > 992) {
      const tl = gsap.timeline({
        defaults: { duration: 1, ease: Power3.easeOut },
      });
      tl.from("#demo", { autoAlpha: 0 })
        .from(
          ".text-reveal .animation-1",
          { duration: 0.8, y: "100%", opacity: 0, ease: "power1.out" },
          "=+1"
        )
        .from(".text-reveal .animation-2", {
          duration: 0.8,
          y: "100%",
          opacity: 0,
          ease: "power1.out",
        })
        .from(".buttonGroup", {
          xPercent: -900,
          duration: 0.8,
          ease: "power3.out",
        })
        .from(".loader", {
          xPercent: 900,
          rotate: 360,
          duration: 0.8,
          ease: "power3.easeout",
        });
    }
  }, []);

  const addMainnetToMetaMask = async () => {
    if (typeof window.ethereum === "undefined") {
      toast.error("Wallet Not Found");
      return;
    }
    try {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: "0x89",
            chainName: "Polygon",
            nativeCurrency: {
              name: "MATIC",
              symbol: "MATIC",
              decimals: 18,
            },
            rpcUrls: [polygonchainRPC],
            blockExplorerUrls: ["https://polygonscan.com/"],
          },
        ],
      });
    } catch (error) {
      console.error(error);
    }
  };


  return (
    <>
      <section className="banner">
        <CContainer>
          <div className="topInformation text-center">
            <CRow className="align-items-center">
              <CCol lg="6">
                <div className="loader d-block d-lg-none">
                  <LazyLoad
                    className="coinbox"
                    once
                    placeholder={
                      <svg
                        className="loaderAnim"
                        version="1.1"
                        id="L9"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        viewBox="0 0 100 100"
                        enable-background="new 0 0 0 0"
                      >
                        <path
                          fill="#ffffff"
                          d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                        >
                          <animateTransform
                            attributeName="transform"
                            attributeType="XML"
                            type="rotate"
                            dur="1s"
                            from="0 50 50"
                            to="360 50 50"
                            repeatCount="indefinite"
                          />
                        </path>
                      </svg>
                    }
                  >
                    <img src={nftLvl7} alt="" />

                  </LazyLoad>
                </div>
                <div className="logo  mx-auto">
                  <div className="text-reveal">
                    <div className="animation-1">
                      <h2>Engage with your Digital Assets</h2>
                    </div>
                    <div className="animation-2">
                      <h2>enhance your rewards and stake ZEB</h2>
                    </div>
                  </div>
                </div>

                <div className="buttonGroup mt-4" id="demo" ref={demoelement}>
                  <CButton
                    className="btn1"
                    color="btnMU mx-2"
                    onClick={addMainnetToMetaMask}
                  >
                    <span>Add Mainnet</span>
                  </CButton>
                  <CButton
                    className="btn2"
                    color="btnMU mx-2 "
                    onClick={() => {
                      addToken({
                        data: {
                          address: CoinAddress,
                          symbol: "ZEB",
                          decimals: 18,
                          name: "ZEB",
                        },
                      });
                    }}
                  >
                    <span>Add ZEB</span>
                  </CButton>
                  <Link to="https://swap.zebmoney.io/" target="blank">
                    <CButton
                      className="btn3"
                      color="btnMU mx-2"
                    >
                      <span>Swap</span>
                    </CButton>
                  </Link>
                  <CButton
                    className="btn2"
                    color="btnMU mx-2 "
                    onClick={() => {
                      addToken({
                        data: {
                          address: USDT,
                          symbol: "USDT",
                          decimals: 6,
                          name: "USDT",
                        },
                      });
                    }}
                  >
                    <span>Add USDT</span>
                  </CButton>
                </div>
              </CCol>
              <CCol className="loader d-none d-lg-block">
                <LazyLoad
                  className="coinbox"
                  once
                  placeholder={
                    <svg
                      className="loaderAnim"
                      version="1.1"
                      id="L9"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      viewBox="0 0 100 100"
                      enable-background="new 0 0 0 0"
                    >
                      <path
                        fill="#ffffff"
                        d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                      >
                        <animateTransform
                          attributeName="transform"
                          attributeType="XML"
                          type="rotate"
                          dur="1s"
                          from="0 50 50"
                          to="360 50 50"
                          repeatCount="indefinite"
                        />
                      </path>
                    </svg>
                  }
                >
                  <img src={nftLvl7} alt="" />

                </LazyLoad>
              </CCol>
            </CRow>
            <div className="listViewBx ms:mt-5 pt-4">
              <CRow className="align-items-center">
                <Referral />
              </CRow>
            </div>
          </div>
          <div className="listViewBx ms:mt-5 pt-4">
            <CRow className="align-items-center">
              <GlobalData />
            </CRow>
          </div>
          <div className="listViewBx ms:mt-5 pt-4">
            <CRow className="align-items-center">
              <Statistics />
            </CRow>
          </div>
        </CContainer>
      </section>

      <section className="informationMU headingwrapper">
        <CContainer>
          <CRow className="align-items-center">
            <div className="infotxt">
              <h3> Stake ZEB</h3>
              <p className="infoboost">
                Boost Your Rewards Experience with Seamless, Secure Digital
                Asset Integration.
              </p>
            </div>
            {address && (
              <div className="account">
                <CButton
                  color="btnMU mx-4 mb-4"
                  onClick={() => navigate("/account-overview")}
                >
                  <span>Account Overview</span>
                </CButton>
              </div>
            )}
          </CRow>
        </CContainer>
      </section>
    </>
  );
};

export default Banner;
