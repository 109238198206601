import { CCol, CRow } from "@coreui/react";
import { RefClaim, ReferralData } from "../hooks/useContractData";
import { poolDetails } from "../config";
import { toLocale } from "../hooks/useContract";

export default function RefBox() {
    return (<>
        <section className="position-relative">

            <CRow className="flex row justify-content-center px-2 py-5">
                {poolDetails.map((pool, index) => {
                    const { referals, claimableReward, claimedReward } = ReferralData(pool.pool, pool.days)
                    const { execute: Claim } = RefClaim(pool.pool, pool.days)
                    if (Number(referals) > 0 && Number(claimableReward) > 0) {

                        return (

                            <CCol className="cardsec" sm={6} md={6} lg={4} xxl={4} key={index}>
                                <div className="popular_gaming_thumb w-100">
                                    <div className="refwrap">
                                        <div className="cardheading">{pool.coin}</div>
                                        <div className="py-4 px-2">
                                            <div className="leftinfo mb-3">
                                                <span className="labeltag1">{pool.days} Days</span>
                                            </div>
                                            <div className="leftinfo">
                                                <span className="labeltag">No of Referral</span>
                                                <span className="labelamount">{referals}</span>
                                            </div>
                                            <div className="leftinfo">
                                                <span className="labeltag">UnClaimed Amount</span>
                                                <span className="labelamount">{toLocale(claimableReward)} {pool.coin}</span>
                                            </div>
                                            <div className="leftinfo">
                                                <span className="labeltag">Claimed Amount</span>
                                                <span className="labelamount">{toLocale(claimedReward)} {pool.coin}</span>
                                            </div>
                                        </div>
                                        <div className="stakebtnarea">
                                            <button
                                                className="boxbtn boxbtn1 bgclaim"
                                                onClick={Claim}
                                            >
                                                Claim {pool.coin}
                                                <span></span>
                                            </button> 
                                        </div>
                                    </div>
                                </div>
                            </CCol>
                        )
                    } else { return null }

                })}

            </CRow>
        </section>

    </>)
}