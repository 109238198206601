import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createConfig, WagmiConfig } from 'wagmi'
import { polygon, polygonMumbai } from 'wagmi/chains'
import { createPublicClient, http } from 'viem';



const wagmiConfig = createConfig({
  autoConnect: true,
  publicClient: createPublicClient({
    chain: polygon, // change the chain to polygon on mainnet shift
    transport: http()
  }),
})


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <WagmiConfig config={wagmiConfig}>
    <App />
  </WagmiConfig>
);


reportWebVitals();
