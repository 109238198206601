import React from "react";
import { Outlet } from "react-router-dom";
import topborder from "../Assets/images/topborder.svg"
import bottomborder from "../Assets/images/bottomborder.svg"
import Header from "../Components/Header";
import Footer from "../Components/Footer";



const Layout = () => {
  return (
    <>
      <Header />
     
      <main>
        <div className="borderboxmain">
          <img className="linetopleft" src={topborder} alt="" />
          <img className="linebottomright" src={bottomborder} alt=""/>
        </div>
        <Outlet />
      <Footer />
      </main>
     
    </>
  );
};

export default Layout;
