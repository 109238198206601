import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { shortAddress, toLocale } from "../hooks/useContract";
import { formatUnits } from "viem";
import LaunchIcon from "@mui/icons-material/Launch";
import { CoinAddress, transactionUrl } from "../config";
import { Link } from "react-router-dom";
import CopyToClipboard from "react-copy-to-clipboard";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

export default function TransTable(props) {

  const data = props.props;
  const navigate = useNavigate();

  const [pagination, setPagination] = useState(0);

  // Search Filter Data
  const [search, setSearch] = useState();
  const [filteredLocations, setFilteredLocations] = useState(data);
  const len = filteredLocations?.length;

  const [sortDirection, setSortDirection] = useState("asc"); // 'asc' for ascending and 'desc' for descending
  const [sortField, setSortField] = useState(""); // column/field to sort by

  const statusMappings = {
    'Claimed': 'Claim',
    'Emergency Unstake': 'Removed',
    'Unstake': 'Completed',
  };

  useEffect(() => {
    if (search && data !== undefined) {
      setFilteredLocations(
        data.filter((location) => {
          const status = (location.status ?? "").toLowerCase();
          const mappedStatus = Object.keys(statusMappings).find(key =>
            statusMappings[key].toLowerCase() === status
          );
          return mappedStatus && mappedStatus.toLowerCase().includes(search.toLowerCase());
        })
      );
    } else {
      setFilteredLocations(data);
    }
  }, [search, data]);

  //  Code for pagination
  const [indexOfLast, setindexOfLast] = useState(10);
  const [indexOfFirst, setindexOfFirst] = useState(0);

  function pagechange(event, value) {
    const currentPage = Number(value);
    const dataPerPage = 10;

    const indexOfLastTodo = currentPage * dataPerPage;
    const indexOfFirstTodo = indexOfLastTodo - dataPerPage;

    setindexOfFirst(indexOfFirstTodo);
    setindexOfLast(indexOfLastTodo);
  }
  const pageHandler = () => {
    const PageCount = Math.ceil(len / 10);
    if (!isNaN(PageCount)) { // Ensure PageCount is a valid number before setting it
      setPagination(PageCount);
    }
  };

  useEffect(() => {
    pageHandler();
  }, [len]);

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortDirection("asc");
    }
  };

  const numberSort = (a, b, direction) => {
    return direction === "asc" ? a - b : b - a;
  };

  const stakeAmountSort = (a, b, direction) => {
    const valueA = formatUnits(
      a.amount,
      a.StakeToken.toLowerCase() === CoinAddress.toLowerCase() ? 18 : 6
    );
    const valueB = formatUnits(
      b.amount,
      b.StakeToken.toLowerCase() === CoinAddress.toLowerCase() ? 18 : 6
    );
    return direction === "asc"
      ? Number(valueA) - Number(valueB)
      : Number(valueB) - Number(valueA);
  };

  useEffect(() => {
    if (data !== undefined && sortField) {
      const sortedData = [...data].sort((a, b) => {
        switch (sortField) {
          case "earned":
            return numberSort(a.earned, b.earned, sortDirection);
          case "level":
            return numberSort(a.level, b.level, sortDirection);
          case "amount":
            return stakeAmountSort(a, b, sortDirection);
          case "days":
            return numberSort(a.pool, b.pool, sortDirection);
          // ... add more cases for other fields ...
          default:
            return 0;
        }
      });
      setFilteredLocations(sortedData);
    }
  }, [sortField, sortDirection, data]);
  const getStatusText = (row) => {
    if (row.status === "Completed") return "Unstake";
    if (row.status === "Removed") return "Emergency Unstake";
    if (row.status === "Claim") return "Claimed";
    return "";
  };
  const notify = () => toast.success("Copy Successfully");
  return (
    <>
      <div className="searchbox flex justify-content-between align-items-center flex-row-reverse">
        <form className="form">
          <label htmlFor="search">
            <input
              className="input"
              type="text"
              required=""
              placeholder="Description"
              id="search"
              onChange={(e) => setSearch(e.target.value)}
              autoComplete="off"
            />
            <div className="fancy-bg" />
            <div className="search">
              <svg
                viewBox="0 0 24 24"
                aria-hidden="true"
                className="r-14j79pv r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-4wgw6l r-f727ji r-bnwqim r-1plcrui r-lrvibr"
              >
                <g>
                  <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z" />
                </g>
              </svg>
            </div>
            <button
              className="close-btn"
              type="reset"
              onClick={() => setSearch()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </label>
        </form>
        <div className="backsidebtn" onClick={() => navigate("/")}>
          <ArrowBackIcon />
        </div>
      </div>
      <TableContainer component={Paper} sx={{ marginTop: "50px" }}>
        <Table aria-label="collapsible table">
          <TableHead className="incometittle">
            <TableRow>
              <TableCell>Sr No</TableCell>
              <TableCell align="center">Tx Hash</TableCell>
              <TableCell align="center">Wallet Address</TableCell>
              <TableCell align="center">Description</TableCell>
              <TableCell align="center">Staked Amount</TableCell>
              <TableCell align="center" onClick={() => handleSort("amount")}>
                Received Amount
                {sortField === "amount" && sortDirection === "asc" ? (
                  <ArrowDropUpIcon />
                ) : (
                  <ArrowDropDownIcon />
                )}
              </TableCell>
              <TableCell align="center" onClick={() => handleSort("days")}>
                Pool
                {sortField === "days" && sortDirection === "asc" ? (
                  <ArrowDropUpIcon />
                ) : (
                  <ArrowDropDownIcon />
                )}
              </TableCell>
              <TableCell align="center">Penalty Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredLocations ? (
              filteredLocations
                .slice(indexOfFirst, indexOfLast)
                .map((row, i) => (
                  <>
                    <TableRow className="incometable">
                      <TableCell component="th" scope="row">
                        {i + indexOfFirst + 1}
                      </TableCell>
                      <TableCell align="center">
                        <Link
                          style={{ textDecoration: "none", color: "#fff" }}
                          to={transactionUrl + row?.transactionHash}
                          target="blank"
                        >
                          <span className="d-flex align-items-center justify-content-center">
                            {shortAddress(row?.transactionHash)}
                            <LaunchIcon
                              sx={{
                                width: "16px",
                                marginLeft: "3px",
                                color: "#2c70b3",
                              }}
                            />
                          </span>
                        </Link>
                      </TableCell>
                      <CopyToClipboard
                        text={row.user}
                      >
                        <TableCell align="center" onClick={notify}>
                          <span className="d-flex align-items-center justify-content-center">
                            {shortAddress(row?.user)}
                            <CopyAllIcon
                              sx={{ width: "17px", marginLeft: "3px" }}
                            />
                          </span>
                        </TableCell>
                      </CopyToClipboard>
                      <TableCell align="center">{getStatusText(row)}</TableCell>
                      <TableCell align="center">
                        {toLocale(
                          formatUnits(
                            row?.StakeAmount,
                            (row?.StakeToken).toLowerCase() === CoinAddress.toLowerCase() ? 18 : 6
                          ) * (row?.status === "Removed" ? 100 / 90 : 1)
                        )}  {(row?.StakeToken).toLowerCase() ===
                          CoinAddress.toLowerCase()
                          ? "ZEB"
                          : "USDT"}
                      </TableCell>
                      <TableCell align="center">
                        {toLocale(
                          formatUnits(
                            row?.amount,
                            (row?.StakeToken).toLowerCase() ===
                              CoinAddress.toLowerCase()
                              ? 18
                              : 6
                          )
                        )} {(row?.StakeToken).toLowerCase() ===
                          CoinAddress.toLowerCase()
                          ? "ZEB"
                          : "USDT"}
                      </TableCell>
                      <TableCell align="center">{row?.pool} Days</TableCell>
                      <TableCell align="center">
                        {toLocale(
                          row?.status === "Removed"
                            ? formatUnits(
                              row?.StakeAmount,
                              (row?.StakeToken).toLowerCase() === CoinAddress.toLowerCase() ? 18 : 6
                            ) * 100 / 90 * 0.1
                            : 0
                        )}
                      </TableCell>
                    </TableRow>
                  </>
                ))
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
        {filteredLocations?.length > 0 ? (
          ""
        ) : (
          <div className="nodata">
            <h4>No Data Found</h4>
          </div>
        )}
      </TableContainer>
      {pagination <= 1 ? (
        ""
      ) : (
        <>
          <Pagination
            className="myPagination"
            onChange={pagechange}
            count={pagination}
            color="secondary"
          />
        </>
      )}
    </>
  );
}
