import { useCallback, useEffect, useMemo, useState } from "react";
import { graphQlUrl } from "../config";
import axios from "axios";
import { useAccount } from "wagmi";
import { formatEther, formatUnits } from "viem";
import { useQueryAndSetAddress } from "./userAddress";

export const AdminGraph = () => {
    const [data, setData] = useState([])
    const [totalStaked, setTotalStaked] = useState([])
    const [referralData, setReferralData] = useState([])
    const [analytics, setanalytics] = useState({
        TotalUsdtStaked: 0,
        TotalZebStaked: 0,
        UsdtStakeLength: 0,
        ZebStakeLength: 0,

    })

    // const { address } = useAccount()
    const { address } = useQueryAndSetAddress()


    const fetchGraphData = useCallback(async () => {
        try {
            if (address) {
                const options = {
                    method: 'POST',
                    url: graphQlUrl,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: {
                        query: `{ 
                          userPersonalStakeDatas(orderBy: blockTimestamp, orderDirection: desc){
                            user
                            StakeToken
                            amount
                            blockTimestamp
                            pool
                            transactionHash
                          }
                          
                          universalDatas {
                            id
                            TotalZebStaked
                            TotalUsdtStaked
                            TotalUsdtRemoved
                            TotalZebRemoved
                            UsdtStakeLength
                            ZebStakeLength
                          }

                          poolTotalStakes {
                            StakeAmount
                            StakeToken
                            poolAddress
                          }
                          referalIncomePoints {
                            level
                            poolAddress
                            stakeAmount
                            stakeToken
                          }
                                                 
                        }`
                    }
                };
                const response = await axios.request(options);
                const res = response.data.data;

                if (res.userPersonalStakeDatas.length > 0) {
                    setData(res.userPersonalStakeDatas)
                }
                if (res.universalDatas.length > 0) {
                    setanalytics({
                        TotalUsdtStaked: formatUnits(res.universalDatas[0].TotalUsdtStaked, 6),
                        TotalZebStaked: formatEther(res.universalDatas[0].TotalZebStaked),
                        UsdtStakeLength: res.universalDatas[0].UsdtStakeLength,
                        ZebStakeLength: res.universalDatas[0].ZebStakeLength,
                    })
                }
                if (res.poolTotalStakes.length > 0) {

                    setTotalStaked(res.poolTotalStakes)
                }
                if (res.referalIncomePoints.length > 0) {
                    setReferralData(res.referalIncomePoints)
                }


            }


        } catch (err) {
            console.error(err)
        }
    }, [address, setData])



    useEffect(() => {
        const inter = setInterval(() => {
            fetchGraphData()
        }, 10000);

        return () => clearInterval(inter)
    }, [fetchGraphData, address])

    useEffect(() => {
        fetchGraphData()
    }, [fetchGraphData, address])

    return useMemo(() => {
        return {
            data,
            analytics,
            totalStaked,
            referralData
        }
    }, [data, analytics, totalStaked, referralData])
}


