import { useCallback, useEffect, useMemo, useState } from "react";
import { graphQlUrl } from "../config";
import axios from "axios";
import { useAccount } from "wagmi";
import { useQueryAndSetAddress } from "./userAddress";

export const useGraphData = () => {
    const [data, setData] = useState([])
    // const { address } = useAccount()
    const { address } = useQueryAndSetAddress()


    const fetchGraphData = useCallback(async () => {
        try {
            if (address) {
                const options = {
                    method: 'POST',
                    url: graphQlUrl,
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: {
                        query: `{ 
                            referalIncomeDatas(
                            where: {referal: "${address}" },orderBy: blockTimestamp, orderDirection: desc
                          ) {
                            poolAddress
                            referal
                            refered
                            level
                            status
                            stakeAmount
                            StakeToken
                            earned
                            blockTimestamp
                          }
                            
                          
                          userOnlyTransactionDatas(where:{user:"${address}" }, orderBy: blockTimestamp, orderDirection: desc){
                            user
                            transactionHash
                            level
                            amount  
                            StakeToken
                            pool
                            status
                            blockTimestamp
                            StakeAmount
                          }
                          
                          userPersonalStakeDatas(where:{user:"${address}" },orderBy: blockTimestamp, orderDirection: desc){
                            user
                            StakeToken
                            amount
                            blockTimestamp
                            pool
                            transactionHash
                          }
                         }`
                    }
                };
                const response = await axios.request(options);
                const res = response.data.data;
                    setData(res)
            }
            else {
                setData([])
            }

        } catch (err) {
            console.error(err)
        }
    }, [address, setData])

    useEffect(() => {
        const inter = setInterval(() => {
            fetchGraphData()
        }, 6000);

        return () => clearInterval(inter)
    }, [fetchGraphData, address])

    useEffect(() => {
        fetchGraphData()
    }, [fetchGraphData, address])

    return useMemo(() => {
        return {
            data
        }
    }, [data])
}


