import React, { useCallback, useRef, useState } from "react";
import { shortAddress, toLocale } from "../hooks/useContract";
import { CCol } from "@coreui/react";
import LazyLoad from "react-lazyload";
import gif from "../Assets/images/vv3.gif";
import { Alldata, Approve, GetReward, ReferralOuter, Stake } from "../hooks/useContractData";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-hot-toast";
import CircularProgress from "@mui/material/CircularProgress";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { PoolUSDT180, PoolUSDT360, PoolZEB180, PoolZEB360, PoolZEB90, poolDetails } from "../config";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const Yourstake = () => {
  const { referedBy, canRefer } = ReferralOuter();

  const selectRef = useRef(null);
  const select2Ref = useRef(null);
  const location = useLocation();
  const [Staked, setStaked] = useState(false);
  const [amount, setAmount] = useState("");
  const [pool, setPool] = useState(PoolZEB90);
  const [refer, setrefer] = useState("");
  const [load, setLoad] = useState(true);

  // Export Functions
  const { execute: getRewardData, Data } = GetReward();
  const { execute: ApproveToken } = Approve(pool);
  const { execute: Staking } = Stake(pool);
  const { allowance, HfgBalance } = Alldata(pool);

  const [value, setValue] = useState(PoolZEB90);
  const [day, setDay] = useState(0);
  const [percent, setPercent] = useState(0);
  const [coin, setCoin] = useState();

  const RewardCallback = useCallback(async () => {
    const selectedPool = value;
    const amount = select2Ref.current?.value;

    try {
      setLoad(false);
      const promist = await getRewardData?.(String(amount), selectedPool);
      setLoad(true);
    } catch (err) {
      setLoad(false);
      toast.error(
        err.reason ? err.reason : err.data ? err.data.message : err.message
      );
    }
  }, [getRewardData, value]);

  const StakeCallback = useCallback(async () => {
    try {
      const amount = select2Ref.current?.value;
      if (amount > 0) {

        setStaked(true);
        const promist = await Staking?.(String(amount), String(refer));
        setStaked(false);
      } else {
        toast.error("Please fill up amount")
      }


    } catch (err) {
      setStaked(false);
      toast.error(
        err.reason ? err.reason : err.data ? err.data.message : err.message
      );
    }
  }, [Staking, setStaked, refer]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const ReferralId = queryParams.get('referrer');
    if (canRefer === true && referedBy !== 0) {
      setrefer(referedBy)
    } else {
      setrefer(ReferralId)
    }
  }, [location.search, canRefer, referedBy])


  const handleData = (value) => {
    const poolData = poolDetails.find(item => item.pool.toLowerCase() === value.toLowerCase())
    setDay(poolData?.days)
    setPercent(poolData?.percent)
    setCoin(poolData?.coin)
  };

  const handleChange = (event, newValue) => {

    setPool(newValue)
    setValue(newValue);
  };

  useEffect(() => {
    handleData(value)
  }, [value])

  useEffect(() => {
    // Check if there's a value in the amount and only then fetch reward data
    if (amount && amount > 0) {
      RewardCallback();
    }
  }, [amount, value, RewardCallback]);

  return (
    <>
      <CCol className="cardsec" sm={12} style={{ margin: "0 auto" }}>
        <div className="">
          <a href="#!">
            <LazyLoad
              className="nftsvideo"
              once
              placeholder={
                <svg
                  className="loaderAnim"
                  version="1.1"
                  id="L9"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 100 100"
                  enableBackground="new 0 0 0 0"
                >
                  <path
                    fill="#ffffff"
                    d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                  >
                    <animateTransform
                      attributeName="transform"
                      attributeType="XML"
                      type="rotate"
                      dur="1s"
                      from="0 50 50"
                      to="360 50 50"
                      repeatCount="indefinite"
                    />
                  </path>
                </svg>
              }
            >
              <div className="itembox mt-3">
                <div className="d-flex gifbox">
                  <img src={gif} alt="GIF" />
                </div>
              </div>
              <div className="stakebtnarea">
                <>
                  <p className="fs-6 mt-2" style={{border:"1px solid #ffd600",padding:"8px",borderRadius:"5px"}}>Referral Wallet - {refer !== null ?shortAddress(refer):"You do not have a referrer"}</p>
                  <div className="tabbar flex">
                    <Tabs
                      value={value}
                      ref={selectRef}
                      onChange={handleChange}
                      variant="scrollable"
                      scrollButtons="auto"
                      allowScrollButtonsMobile="mobile"
                    >
                      <Tab label="ZEB-90" value={PoolZEB90} />
                      <Tab label="ZEB-180" value={PoolZEB180} />
                      <Tab label="ZEB-360" value={PoolZEB360} />
                      <Tab label="USDT-180" value={PoolUSDT180} />
                      <Tab label="USDT-360" value={PoolUSDT360} />
                    </Tabs>

                  </div>
                  <div className="inputvalue">
                    <div className="leftwrap">
                      <div className="leftinfo">
                        <span className="labeltag">Aval Balance:</span>
                        <span className="labelspan">
                          <div style={{ marginRight: "4px" }}>{toLocale(HfgBalance)} {coin}</div>
                        </span>
                      </div>
                      <div className="leftinfo">
                        <span className="labeltag">Total Days:</span>
                        <span className="labelspan">
                          <div style={{ marginRight: "4px" }}>{day} DAYS</div>
                        </span>
                      </div>
                      <div className="leftinfo">
                        <span className="labeltag">Total Reward:</span>
                        <span className="labelspan d-flex align-items-center">
                          {load ? (
                            <>{toLocale(Data)} {coin}</>
                          ) : (
                            <CircularProgress size={"16px"} style={{ marginTop: "5px" }} />
                          )}
                        </span>
                      </div>
                      <div className="leftinfo">
                        <span className="labeltag">Per Day Rewards:</span>
                        <span className="d-flex align-items-center">
                          {load ? (
                            Data > 0 && day > 0 ? (
                              toLocale(Data / day)
                            ) : (
                              0
                            )
                          ) : (
                            <CircularProgress size={"16px"} />
                          )}

                        </span>
                      </div>
                    </div>
                    <div className="rightwrap">
                      <div className="rightinfo">
                        <span className="labelapr">APR</span>
                      </div>
                      <div className="rightinfo">
                        <span className="labelpercent">{percent}%</span>
                      </div>

                    </div>
                  </div>
                  <div className="inputbox">
                    <div className="inputwrap">
                      <input
                        ref={select2Ref}
                        className="value"
                        type="number"
                        placeholder="Enter Amount"
                        value={amount}
                        onKeyDown={(e) => {
                          const allowedKeys = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "Backspace", "ArrowLeft", "ArrowRight", "Delete", "."];
                          if (!allowedKeys.includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          setAmount(e.target.value);
                          RewardCallback();
                        }}
                      />
                      <span className="maxbtn" onClick={() => setAmount(HfgBalance)}>Max</span>
                    </div>
                    {(allowance > 0 && (
                      <>
                        <button className="boxbtn" onClick={StakeCallback} disabled={Staked}>
                          {Staked ? "Staking..." : `Stake ${coin}`}
                        </button>
                      </>
                    )) || (
                        <button className="boxbtn" onClick={() => {
                          if (amount > 0) {
                            ApproveToken()
                          } else {
                            toast.error("Please fill up amount")
                          }
                        }}>
                          Approve {coin}
                          <span></span>
                        </button>
                      )}
                  </div>

                  <br />
                  <br />
                  <p className=" notearea ">
                    *<b>Note</b> - If you unstake ZEB or USDT before maturity, 10% of
                    your staked amount will be deducted. Any pending rewards at
                    that time will not be processed. Ensure that you wait until
                    maturity to avoid these penalties.
                  </p>
                </>
              </div>
            </LazyLoad>
          </a>
        </div>
      </CCol>
    </>
  );
};

export default Yourstake;
