import { ethers } from "ethers";
import { formatEther } from "ethers/lib/utils";
import { web3Provider } from "../config";

function shortAddress(address) {
    if (address === null || address === undefined ) {
        return
    }
    return `${address.substr(0, 4)}...${address.substr(-4)}`;
}

function formatNumber(val, decimals=4) {
    return parseFloat(parseFloat(formatEther(val)).toFixed(decimals)).toLocaleString()
}

function toLocale(val, decimals=4) {
    return Number(parseFloat(val).toFixed(decimals)).toLocaleString()
}

export {
    shortAddress,
    formatNumber,
    toLocale
}



function getSigner() {
    return (new ethers.providers.Web3Provider(window.ethereum)).getSigner()
}


export const useContractInstance = (contractAddress, contractABI, signerIfPossible = false) => {
    const contract = new ethers.Contract(contractAddress, contractABI, signerIfPossible ? getSigner() : web3Provider)

    return contract
}