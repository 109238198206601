import { ethers } from 'ethers'
import { CoinAddress, LockingPeriod, PoolUSDT180, PoolUSDT360, PoolZEB180, PoolZEB360, PoolZEB90, ReferalContract, USDT, defaultNetwork, poolDetails, web3Provider } from '../config'
import StakeCoin from "../abi/StakeCoin.json"
import ERCAbi from "../abi/erc20.json"
// import testingAbi from "../abi/testingAbi.json"
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAccount, useNetwork } from "wagmi";
import { useContractInstance } from './useContract'
import { createPublicClient, formatUnits, http, parseEther, parseUnits } from 'viem'
import { toast } from "react-hot-toast"
import OracleAbi from "../abi/PoolOracle.json"
import { OracleAddress } from '../config'
import referralAbi from "../abi/referralAbi.json"
import { useQueryAndSetAddress } from './userAddress'


const polygonMumbai = {
    id: 80001,
    name: "Polygon Mumbai",
    network: "maticmum",
    nativeCurrency: {
        name: "MATIC",
        symbol: "MATIC",
        decimals: 18,
    },
    rpcUrls: {
        alchemy: {
            http: ["https://polygon-mumbai.g.alchemy.com/v2"],
            webSocket: ["wss://polygon-mumbai.g.alchemy.com/v2"],
        },
        infura: {
            http: ["https://polygon-mumbai.infura.io/v3"],
            webSocket: ["wss://polygon-mumbai.infura.io/ws/v3"],
        },
        default: {
            http: ["https://special-polished-lake.matic-testnet.quiknode.pro/241402282ea6046c7d4516d7ec9610cad44e9838/"],
        },
        public: {
            http: ["https://special-polished-lake.matic-testnet.quiknode.pro/241402282ea6046c7d4516d7ec9610cad44e9838/"],
        },
    },
    blockExplorers: {
        etherscan: {
            name: "PolygonScan",
            url: "https://mumbai.polygonscan.com",
        },
        default: {
            name: "PolygonScan",
            url: "https://mumbai.polygonscan.com",
        },
    },
    contracts: {
        multicall3: {
            address: "0xca11bde05977b3631167028862be2a173976ca11",
            blockCreated: 25770160,
        },
    },
    testnet: true,
};

const polygon = {
    id: 137,
    name: "Polygon",
    network: "matic",
    nativeCurrency: {
        name: "MATIC",
        symbol: "MATIC",
        decimals: 18,
    },
    rpcUrls: {
        alchemy: {
            http: ["https://polygon-mainnet.g.alchemy.com/v2"],
            webSocket: ["wss://polygon-mainnet.g.alchemy.com/v2"],
        },
        infura: {
            http: ["https://polygon-mainnet.infura.io/v3"],
            webSocket: ["wss://polygon-mainnet.infura.io/ws/v3"],
        },
        default: {
            // http: ["https://virulent-soft-glade.matic.quiknode.pro/f31fd1dab926f81b6236cd10e84ced895dd6ddf7/"],
            http: ["https://polygon-rpc.com/"],
        },
        public: {
            // http: ["https://virulent-soft-glade.matic.quiknode.pro/f31fd1dab926f81b6236cd10e84ced895dd6ddf7/"],
            http: ["https://polygon-rpc.com/"],
        },
    },
    blockExplorers: {
        etherscan: {
            name: "PolygonScan",
            url: "https://polygonscan.com",
        },
        default: {
            name: "PolygonScan",
            url: "https://polygonscan.com",
        },
    },
    contracts: {
        multicall3: {
            address: "0xca11bde05977b3631167028862be2a173976ca11",
            blockCreated: 25770160,
        },
    },
};



export const Stake = (poolAddress) => {
    const { address } = useAccount()


    const poolData = poolDetails.find(item => item.pool.toLowerCase() === poolAddress.toLowerCase())


    const stakeInstance = useContractInstance(poolAddress, StakeCoin, address !== undefined && address !== null)

    const execute = useCallback((amount, refer) => {
        return new Promise(async (resolve, reject) => {

            // Check if the refer parameter is exactly the string "null"
            if (refer === "null") {
                toast.error("You do not have a referrer.");
                return resolve(null);
            }
            try {
                let stakeAmount = ethers.utils.parseUnits(amount, poolData.coin === "ZEB" ? 18 : 6);
                const transaction = await stakeInstance.Stake(stakeAmount,
                    (refer !== "null" ? refer : ethers.constants.AddressZero)
                );

                const loadingToast = await toast.promise(transaction.wait(), {
                    loading: "Staking...",
                    success: "Staked Successfully",
                    error: "Failed to Stake",
                });
                toast.dismiss(loadingToast);
                resolve(true)

            } catch (err) {
                console.warn(err);
                resolve(null)
                toast.error(err.reason ? err.reason : err.data ? err.data.message : err.message)
            }
        })
    }, [stakeInstance, poolData.coin])

    return useMemo(() => {
        return {
            execute
        }
    }, [execute])


};

export const Approve = (pool) => {
    const { address } = useAccount()

    const poolData = poolDetails.find(item => item.pool.toLowerCase() === pool.toLowerCase())

    const Token = useContractInstance(poolData.coin === "ZEB" ? CoinAddress : USDT, ERCAbi, address !== undefined && address !== null)

    const execute = useCallback(() => {
        return new Promise(async (resolve, reject) => {
            try {
                const txn = await Token.increaseAllowance(pool, ethers.constants.MaxUint256);
                const loadingToast = await toast.promise(txn.wait(), {
                    loading: "Approving...",
                    success: "Approved Successfully",
                    error: "Failed to Approve",
                });
                toast.dismiss(loadingToast);

            } catch (err) {
                if (address === undefined) {
                    toast.error("Connect Wallet")
                }
                toast.error(err.reason ? err.reason : err.data ? err.data.message : err.message)
                resolve(null)
            }
        })
    }, [Token, pool,address])

    return useMemo(() => {
        return {
            execute
        }
    }, [execute])


}

export const ClaimCoin = (pool) => {
    const { address } = useAccount()


    const stakeInstance = useContractInstance(pool, StakeCoin, address !== undefined && address !== null)
    const execute = useCallback(() => {
        return new Promise(async (resolve, reject) => {
            try {

                const transaction = await stakeInstance.UserClaimReward();
                const loadingToast = await toast.promise(transaction.wait(), {
                    loading: "Claiming...",
                    success: "Claimed Successfully",
                    error: "Failed to Claim",
                });
                toast.dismiss(loadingToast);

            } catch (err) {
                console.error(err);
                toast.error(err.reason ? err.reason : err.data ? err.data.message : err.message)
                resolve(null)

            }
        })
    }, [stakeInstance])

    return useMemo(() => {
        return {
            execute
        }
    }, [execute])

};

export const RemoveStake = (pool) => {
    const { address } = useAccount()

    const stakeInstance = useContractInstance(pool, StakeCoin, address !== undefined && address !== null)
    const execute = useCallback(() => {
        return new Promise(async (resolve, reject) => {
            try {

                const transaction = await stakeInstance.RemoveStake();
                const loadingToast = await toast.promise(transaction.wait(), {
                    loading: "Removing...",
                    success: "Removed Successfully",
                    error: "Failed to Remove",
                });
                toast.dismiss(loadingToast);

            } catch (err) {
                console.error(err);
                resolve(null)
                toast.error(err.reason ? err.reason : err.data ? err.data.message : err.message)

            }
        })
    }, [stakeInstance])

    return useMemo(() => {
        return {
            execute
        }
    }, [execute])

}


export const GetReward = () => {
    const [Data, setData] = useState(0);

    const execute = useCallback((val, pool) => {
        return new Promise(async (resolve, reject) => {
            try {
                const stakeInstance = new ethers.Contract(pool, StakeCoin, web3Provider)
                const amount = parseEther(val)
                let Data = await stakeInstance.getRewardData(amount);
                Data = ethers.utils.formatUnits(Data, 18)
                setData(Data)

                resolve(true)
            } catch (err) {
                resolve(null)
                console.warn(err);
            }
        })


    }, [])

    return useMemo(() => {
        return {
            execute,
            Data,
        }
    }, [execute, Data])
}


export const RefClaim = (pool, day) => {
    const { address } = useAccount()
    const stakeInstance = useContractInstance(ReferalContract, referralAbi, address !== undefined && address !== null)
    const poolData = poolDetails.find(item => item.pool.toLowerCase() === pool.toLowerCase()).coin

    const execute = useCallback(() => {
        return new Promise(async (resolve, reject) => {
            try {
                const transaction = await stakeInstance.ClaimTotalReferalEarn(poolData === "ZEB" ? CoinAddress : USDT, day, pool);
                const loadingToast = await toast.promise(transaction.wait(), {
                    loading: "Claiming...",
                    success: "Claimed Successfully",
                    error: "Failed to Claim",
                });
                toast.dismiss(loadingToast);

            } catch (err) {
                console.error(err);
                toast.error(err.reason ? err.reason : err.data ? err.data.message : err.message)
                resolve(null)

            }
        })
    }, [stakeInstance, pool, day, poolData])

    return useMemo(() => {
        return {
            execute
        }
    }, [execute])

};


export function Alldata(pool) {
    const { chain } = useNetwork()
    const isCorrectNetwork = chain?.id === defaultNetwork
    const { address } = useQueryAndSetAddress()

    const [allowance, setAllowance] = useState(0);
    const [HfgBalance, setHfgBalance] = useState(0);
    const poolData = poolDetails.find(item => item.pool.toLowerCase() === pool.toLowerCase())

    const Token = useContractInstance(poolData.coin === "ZEB" ? CoinAddress : USDT, ERCAbi)

    const fetchHFGTokenInfo = useCallback(async () => {
        try {

            const hfgAllowance = await Token.allowance(address, pool)
            const _hfgAllowance = formatUnits(hfgAllowance, poolData.coin === "ZEB" ? 18 : 6)
            setAllowance(_hfgAllowance)

            const hfgBalance = await Token.balanceOf(address)
            const _hfgBalance = formatUnits(hfgBalance, poolData.coin === "ZEB" ? 18 : 6)
            setHfgBalance(_hfgBalance)

        } catch (err) {
            console.error(err)
        }
    }, [Token, address, pool, poolData?.coin])



    useEffect(() => {
        const interval = setInterval(async () => {
            if (address) {
                fetchHFGTokenInfo()
            }

        }, 8000);
        return () => clearInterval(interval);
    }, [fetchHFGTokenInfo, address, pool]);

    useEffect(() => {
        if (address) {

            fetchHFGTokenInfo();
        }

    }, [fetchHFGTokenInfo, address, pool])


    return useMemo(() => {
        return {
            allowance,
            HfgBalance
        }
    }, [allowance, HfgBalance])

}

export function ReferralOuter() {
    const { chain } = useNetwork()
    const isCorrectNetwork = chain?.id === defaultNetwork

    const { address } = useQueryAndSetAddress()


    const [referedBy, setReferedBy] = useState(0);
    const [canRefer, setCanRefer] = useState(false);

    const OracleInt = useContractInstance(OracleAddress, OracleAbi)

    const getUserPoolData = useCallback(async () => {

        try {
            const data = await OracleInt.UserReferalData(address)

            if (data?.length > 0) {
                if (data[0] === ethers.constants.AddressZero) {
                    setReferedBy(0);
                }
                else {
                    setReferedBy(data[0])
                }
                setCanRefer(data[1])
            }
        } catch (error) {
            console.error(error);
        }

    }, [address, OracleInt])

    useEffect(() => {
        if (address) {
            getUserPoolData()
        } else {
            setReferedBy(0);
            setCanRefer(false);
        }
    }, [address, getUserPoolData])

    useEffect(() => {
        const interval = setInterval(async () => {
            if (address) {
                getUserPoolData()
            }

        }, 12000);
        return () => clearInterval(interval);
    }, [getUserPoolData, address,]);

    return useMemo(() => {
        return {
            referedBy,
            canRefer,
        }
    }, [referedBy, canRefer])
}

export function ReferralData(pool, day) {
    const { chain } = useNetwork()
    const isCorrectNetwork = chain?.id === defaultNetwork

    const { address } = useQueryAndSetAddress()
    const [referals, setReferals] = useState(0);
    const [claimableReward, setClaimableReward] = useState(0);
    const [claimedReward, setClaimedReward] = useState(0);

    const client = createPublicClient({
        chain: polygon,
        transport: http(),
    });

    const poolData = poolDetails.find(item => item.pool.toLowerCase() === pool.toLowerCase()).coin


    const getUserPoolData = useCallback(async () => {
        try {

            const data = await client.multicall({
                contracts: [
                    {
                        address: OracleAddress,
                        abi: OracleAbi,
                        functionName: 'ReferedHolderCount',
                        args: [address, pool],
                    },
                    {
                        address: ReferalContract,
                        abi: referralAbi,
                        functionName: 'getTotalClaimedReferalEarns',
                        args: [address, pool],
                    },
                    {
                        address: ReferalContract,
                        abi: referralAbi,
                        functionName: 'getTotalReferalClaimableReward',
                        args: [address, day, pool],
                    },

                ],
                allowFailure: false
            })

            if (data?.length > 0) {
                setReferals(parseFloat(data[0]))
                setClaimedReward(formatUnits(data[1], poolData === "ZEB" ? 18 : 6))
                setClaimableReward(formatUnits(data[2], poolData === "ZEB" ? 18 : 6))
            }

        } catch (error) {
            console.error(error);
        }

    }, [address, client, pool, day, poolData])

    useEffect(() => {
        if (address) {
            getUserPoolData()
        } else {
            setReferals(0)
            setClaimedReward(0)
            setClaimableReward(0)
        }
    }, [address, getUserPoolData])

    useEffect(() => {
        const interval = setInterval(async () => {
            if (address) {
                getUserPoolData()
            }

        }, 10000);
        return () => clearInterval(interval);
    }, [getUserPoolData, address,]);

    return useMemo(() => {
        return {
            referals,
            claimableReward,
            claimedReward
        }
    }, [referals, claimableReward, claimedReward])
}


export function UserCalling(pool) {
    const { address } = useQueryAndSetAddress()

    const { chain } = useNetwork()
    const isCorrectNetwork = chain?.id === defaultNetwork

    const [Timer, setTimer] = useState(0);
    const [TotalReward, setTotalreward] = useState(0);
    const [tag, setTag] = useState(0);
    const [claimedReward, setClaimedReward] = useState(0);
    const [claimableReward, setClaimableReward] = useState(0);
    const [stakeAmount, setStakeAmount] = useState(0);

    const poolData = poolDetails.find(item => item.pool.toLowerCase() === pool.toLowerCase())
    const client = createPublicClient({
        chain: polygon,
        transport: http(),
    });

    const decimals = poolData.coin === "ZEB" ? 18 : 6

    const getUserPoolData = useCallback(async () => {

        try {

            const data = await client.multicall({
                contracts: [
                    {
                        address: pool,
                        abi: StakeCoin,
                        functionName: 'Staker',
                        args: [address],
                    },
                    {
                        address: pool,
                        abi: StakeCoin,
                        functionName: 'ClaimedReward',
                        args: [address],
                    },
                    {
                        address: pool,
                        abi: StakeCoin,
                        functionName: 'getUnclaimedReward',
                        args: [address],
                    },

                    {
                        address: pool,
                        abi: StakeCoin,
                        functionName: 'getUnStakeTime',
                        args: [address],
                    },
                    {
                        address: pool,
                        abi: StakeCoin,
                        functionName: 'getTotalReward',
                        args: [address],
                    },
                ],
                allowFailure: false
            })

            if (data?.length > 0) {
                setStakeAmount(formatUnits(data[0][0], decimals))
                setClaimedReward(formatUnits(data[1], decimals))
                setClaimableReward(formatUnits(data[2], decimals))
                setTotalreward(formatUnits(data[4], decimals))
                setTimer(parseFloat(data[3]))
                if ((parseFloat(data[3]) * 1000) > Date.now()) {
                    setTag(0)
                } else {
                    if (((parseFloat(data[3]) + LockingPeriod) * 1000) < Date.now()) {
                        setTag(1)
                    } else {
                        setTag(2)
                    }
                }
            }

        } catch (error) {
            console.error(error);
        }

    }, [address, client, pool, decimals])

    useEffect(() => {
        if (address) {
            getUserPoolData()
        } else {
            setStakeAmount(0)
            setClaimedReward(0)
            setClaimableReward(0)
            setTotalreward(0)
        }
    }, [address, pool, getUserPoolData])

    useEffect(() => {
        const interval = setInterval(async () => {
            if (address) {
                getUserPoolData()
            }

        }, 8000);
        return () => clearInterval(interval);
    }, [getUserPoolData, address, pool]);

    return useMemo(() => {
        return {
            stakeAmount,
            claimableReward,
            claimedReward,
            tag,
            Timer,
            TotalReward,

        }
    }, [stakeAmount, claimableReward, claimedReward, tag, Timer, TotalReward])
}

export function AdminFunction() {
    const { address } = useAccount()


    const zebToken = useContractInstance(CoinAddress, ERCAbi, address !== undefined && address !== null)
    const USDTToken = useContractInstance(USDT, ERCAbi, address !== undefined && address !== null)


    const AddLiquidity = useCallback((token, amount, pool) => {
        return new Promise(async (resolve, reject) => {
            try {
                console.log(typeof (amount));
                if (!amount) {
                    toast.error("Please enter Amount");
                    resolve(false); // resolving with failure flag
                    return;
                }
                const isCoin = token.toLowerCase() === CoinAddress.toLowerCase();
                const decimalPlaces = isCoin ? 18 : 6;
                const targetToken = isCoin ? zebToken : USDTToken;

                const convertAmount = parseUnits(amount, decimalPlaces);

                const txn = await targetToken.transfer(pool, convertAmount);

                const loadingToast = await toast.promise(txn.wait(), {
                    loading: "Processing...",
                    success: "Transaction Successfully",
                    error: "Failed",
                });
                toast.dismiss(loadingToast);
                resolve(true);
            } catch (err) {
                toast.error(err.reason ? err.reason : err.data ? err.data.message : err.message);
                resolve(null);
            }
        });
    }, [zebToken, USDTToken]);


    const EmergencyWithdraw = useCallback((token, amount, pool) => {
        return new Promise(async (resolve, reject) => {
            try {
                if (!window.ethereum) {
                    toast.error("Please Connet Wallet");
                    resolve(false); // resolving with failure flag
                    return;
                }
                if (!amount) {
                    toast.error("Please enter Amount");
                    resolve(false); // resolving with failure flag
                    return;
                }
                const signer = new ethers.providers.Web3Provider(window.ethereum).getSigner()
                const contract = new ethers.Contract(pool, StakeCoin, signer)
                const convertAmount = parseUnits(amount, token === CoinAddress ? 18 : 6)
                const txn = await contract.emergencyWithdrawAmt(token, convertAmount)

                const loadingToast = await toast.promise(txn.wait(), {
                    loading: "Processing...",
                    success: "Transaction Successfully",
                    error: "Failed",
                });
                toast.dismiss(loadingToast);

            } catch (err) {
                toast.error(err.reason ? err.reason : err.data ? err.data.message : err.message)
                resolve(null)
            }
        })
    }, [])

    const EmergencyWithdrawAll = useCallback((token, pool) => {
        return new Promise(async (resolve, reject) => {
            try {
                if (!window.ethereum) {
                    toast.error("Please Connet Wallet");
                    resolve(false); // resolving with failure flag
                    return;
                }
                const signer = new ethers.providers.Web3Provider(window.ethereum).getSigner()
                const contract = new ethers.Contract(pool, StakeCoin, signer)
                const txn = await contract.emergencyWithdraw(token)

                const loadingToast = await toast.promise(txn.wait(), {
                    loading: "Processing...",
                    success: "Transaction Successfully",
                    error: "Failed",
                });
                toast.dismiss(loadingToast);

            } catch (err) {
                toast.error(err.reason ? err.reason : err.data ? err.data.message : err.message)
                resolve(null)
            }
        })
    }, [])


    return useMemo(() => {
        return {
            AddLiquidity,
            EmergencyWithdraw,
            EmergencyWithdrawAll,
        }
    }, [AddLiquidity, EmergencyWithdraw, EmergencyWithdrawAll])


}

export function ContractBalance() {


    const initialState = {
        zeb90: 0,
        zeb180: 0,
        zeb360: 0,
        usdt180: 0,
        usdt360: 0,
    };

    const [balance, setBalance] = useState(initialState);

    const fetchTokenInfo = useCallback(async () => {
        try {
            const client = createPublicClient({
                chain: polygon,
                transport: http(),
            });

            const data = await client.multicall({
                contracts: [
                    {
                        address: CoinAddress,
                        abi: ERCAbi,
                        functionName: 'balanceOf',
                        args: [PoolZEB90],
                    },
                    {
                        address: CoinAddress,
                        abi: ERCAbi,
                        functionName: 'balanceOf',
                        args: [PoolZEB180],
                    },
                    {
                        address: CoinAddress,
                        abi: ERCAbi,
                        functionName: 'balanceOf',
                        args: [PoolZEB360],
                    },
                    {
                        address: USDT,
                        abi: ERCAbi,
                        functionName: 'balanceOf',
                        args: [PoolUSDT180],
                    },
                    {
                        address: USDT,
                        abi: ERCAbi,
                        functionName: 'balanceOf',
                        args: [PoolUSDT360],
                    },


                ],
                allowFailure: false
            })
            if (data.length > 0) {
                setBalance({
                    zeb90: formatUnits(data[0], 18),
                    zeb180: formatUnits(data[1], 18),
                    zeb360: formatUnits(data[2], 18),
                    usdt180: formatUnits(data[3], 6),
                    usdt360: formatUnits(data[4], 6),
                });
            }


        } catch (err) {
            console.error(err)
        }
    }, [])


    useEffect(() => {
        const interval = setInterval(async () => {
            fetchTokenInfo()

        }, 8000);
        return () => clearInterval(interval);
    }, [fetchTokenInfo]);

    useEffect(() => {

        fetchTokenInfo();

    }, [fetchTokenInfo])


    return useMemo(() => {
        return {
            ...balance
        }
    }, [balance])

}




// export const Testing = () => {
//     const { address } = useAccount()



//     const Token = useContractInstance(testingContract, testingAbi, address !== undefined && address !== null)

//     const execute = useCallback(() => {
//         return new Promise(async (resolve, reject) => {
//             try {
//                 await Token.getUSDT()
//                 const txn = await Token.getZEB()
//                 const loadingToast = await toast.promise(txn.wait(), {
//                     loading: "Getting Testing Token...",
//                     success: "Successfully",
//                     error: "Failed",
//                 });
//                 toast.dismiss(loadingToast);

//             } catch (err) {
//                 toast.error(err.reason ? err.reason : err.data ? err.data.message : err.message)
//                 resolve(null)
//             }
//         })
//     }, [Token])

//     return useMemo(() => {
//         return {
//             execute
//         }
//     }, [execute])


// }