import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import "@coreui/coreui/dist/css/coreui.min.css";
import "sweetalert2/src/sweetalert2.scss";
import "../src/Assets/css/main.css";
import Layout from "./Pages/Layout";
import Home from "./Pages/Home";
import IncomeOverview from "./Components/IncomeOverview";
import AdminReport from "./Pages/Admin";
import AdminPanel from "./Pages/AdminPanel";



const AppRoutes = () => {
  return (
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/account-overview" element={<IncomeOverview />} />
          <Route path="/2023-restrictedarea-admin-report" element={<AdminReport />} />
          <Route path="/2023-restrictedarea-admin" element={<AdminPanel />} />
        </Route>
      </Routes>
  );
};

const App = () => {


  return (
    <>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
};

export default App;
