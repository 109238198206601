import { CRow, CCol } from '@coreui/react';
import Wallet from '../Assets/images/Icons/ref-wall2.webp';
import referral from '../Assets/images/Icons/no-of-ref2.webp';
import link from '../Assets/images/Icons/ref-link2.webp';
import { shortAddress, } from '../hooks/useContract';
import { ReferralOuter } from '../hooks/useContractData';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useAccount } from 'wagmi';
import { url } from '../config';
import { toast } from "react-hot-toast"
import gsap from "gsap";
import { Power3 } from "gsap";

import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryAndSetAddress } from '../hooks/userAddress';


export const Referral = () => {
    const navigate = useNavigate()
    const demoelement1 = useRef(null);
    // const { address } = useAccount()
    const { address } = useQueryAndSetAddress()


    const { referedBy, canRefer } = ReferralOuter();

    const notify = () => toast.success('Copied Successfully!')

    useEffect(() => {
        if (window.innerWidth > 992) {
            const tl = gsap.timeline({
                defaults: { duration: 1, ease: Power3.easeOut },
            });
            tl.from("#demo1", { autoAlpha: 1 })
                .from(".inrInfoBx1", { duration: 0.8, y: "100%", opacity: 0, ease: "power1.out" }, "+=4")
                .from(".inrInfoBx2", { duration: 0.8, y: "100%", opacity: 0, ease: "power1.out" },)
                .from(".inrInfoBx3", { duration: 0.8, y: "100%", opacity: 0, ease: "power1.out" },)
                .from(".inrInfoBx4", { duration: 0.8, y: "100%", opacity: 0, ease: "power1.out" },)
        }

    }, []);

    return (
        <CCol md="12">
            <CRow xs={{ cols: 1 }} md={{ cols: 2 }} lg={{ cols: 3 }} className="justify-content-center" ref={demoelement1} id='#demo1'>
                <CCol className='mb-2'>
                    <div className='inrInfoBx inrInfoBx1'>
                        <div className='imginrBx'>
                            <img src={link} alt="Referral Link" />
                        </div>
                        <div className="refbox">
                            <p>Referral link</p>
                            <div className="inputvalue">
                                <div className="input-wrapper">
                                    <CopyToClipboard text={`${url}?referrer=${address}`}>
                                        <div className="inputbar"  >{canRefer ?
                                            <span onClick={notify}>
                                                {url.substring(0, 16)}
                                                <CopyAllIcon style={{ fontSize: '1.2rem', marginLeft: '3px' }} />
                                            </span> : <span>Not Eligible</span>}
                                        </div>
                                    </CopyToClipboard>
                                </div>
                            </div>
                        </div>
                    </div>
                </CCol>
                <CCol className='mb-2'>
                    <div className='inrInfoBx inrInfoBx3'>
                        <div className='imginrBx'>
                            <img src={Wallet} alt="Referral Wallet" />
                        </div>
                        <div className="refbox">
                            <p>Referral Wallet</p>
                            <strong>
                                {shortAddress(referedBy !== 0 ? referedBy : "")}
                            </strong>

                        </div>
                    </div>
                </CCol>
                <CCol className='mb-2'>
                    <div className='inrInfoBx inrInfoBx4'>
                        <div className='imginrBx'>
                            <img src={referral} alt="No. of Referrals" />
                        </div>
                        <div className="refbox">
                            <p>Referral reward</p>
                            <button className='refclaimbtn' onClick={() => navigate("/account-overview/?value=2")}>
                                VIEW
                            </button>

                        </div>
                    </div>
                </CCol>
            </CRow>
        </CCol>
    )
}
export default Referral;
