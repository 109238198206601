import React, { useEffect, useState } from 'react'
import { CRow, CCol } from '@coreui/react';
import claimedrewards from '../Assets/images/Icons/claimed-reward2.webp'
import unclaimed from '../Assets/images/Icons/unclaimed-rewards2.webp'
import ammount from '../Assets/images/Icons/stake2.webp'
import totalreward from '../Assets/images/Icons/total-claimed-reaward.webp'

import { toLocale } from '../hooks/useContract';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { UserCalling } from '../hooks/useContractData';
import { CoinAddress, PoolUSDT180, PoolUSDT360,  PoolZEB180, PoolZEB360, PoolZEB90 } from '../config';
import { useGlobalData } from '../hooks/globalData';
import { formatUnits } from 'viem';


export const Statistics = () => {
    const { data } = useGlobalData()

    const poolData = [
        { pool: PoolZEB90, percent: 25, days: 90, coin: "ZEB", label: "ZEB-90" },
        { pool: PoolZEB180, percent: 30, days: 180, coin: "ZEB", label: "ZEB-180" },
        { pool: PoolZEB360, percent: 36, days: 360, coin: "ZEB", label: "ZEB-360" },
        { pool: PoolUSDT180, percent: 30, days: 180, coin: "USDT", label: "USDT-180" },
        { pool: PoolUSDT360, percent: 36, days: 360, coin: "USDT", label: "USDT-360" },
    ];

    const poolsWithStake = poolData.filter(poolData => UserCalling(poolData.pool)?.stakeAmount > 0);


    const [selectedPool, setSelectedPool] = useState(PoolZEB90);
    const [coinname, setcoinname] = useState("ZEB");
    const [initialSelection, setInitialSelection] = useState(null);


    const { stakeAmount = 0, claimableReward = 0, claimedReward = 0 } = UserCalling(selectedPool) || {};

    const handleChange = (event, newValue) => {
        setSelectedPool(poolData[newValue].pool);
        setcoinname(poolData[newValue].coin);
    };

    useEffect(() => {
        if (poolsWithStake.length > 0) {
            setInitialSelection(poolsWithStake[0].pool);
        }
    }, [poolsWithStake]);

    useEffect(() => {
        if (initialSelection !== null) {
            setSelectedPool(initialSelection);
        }
    }, [initialSelection]);

    const totalClaimAmount = data.find(item => (item.poolAddress).toLowerCase() === selectedPool.toLowerCase());


    return (

        <CCol md="12">
            <div className="tabbar flex justify-content-center">
                {poolData.length > 0 &&
                    <Tabs
                        value={poolData.findIndex(poolData => poolData.pool === selectedPool)}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        allowScrollButtonsMobile="mobile"
                    >
                        {poolData.map((poolData, index) => (
                            <Tab key={index} value={index} label={`${poolData.label} Pool`} />
                        ))}
                    </Tabs>
                }
            </div>
            <CRow xs={{ cols: 1 }} md={{ cols: 2 }} lg={{ cols: 4 }} className="justify-content-center">
                <CCol className='mb-2'>
                    <div className='inrInfoBx'>
                        <div className='imginrBx'>
                            <img src={totalreward} alt='' />
                        </div>
                        <div className="refbox">
                            <p>Total Claimed Rewards</p>
                            <strong>{totalClaimAmount !== undefined ?
                                toLocale(formatUnits(totalClaimAmount.ClaimAmount, (totalClaimAmount?.StakeToken).toLowerCase() === CoinAddress.toLowerCase() ? 18 : 6))
                                : 0} {coinname}
                            </strong>

                        </div>
                    </div>
                </CCol>
                <CCol className='mb-2'>
                    <div className='inrInfoBx'>
                        <div className='imginrBx'>
                            <img src={claimedrewards} alt='' />
                        </div>
                        <div className="refbox">
                            <p>Active Claimed Rewards</p>
                            <strong>
                                {toLocale(claimedReward)} {coinname}
                            </strong>

                        </div>
                    </div>
                </CCol>
                <CCol className='mb-2'>
                    <div className='inrInfoBx'>
                        <div className='imginrBx'>
                            <img src={unclaimed} alt='' />
                        </div>
                        <div className="refbox">
                            <p>Unclaimed Rewards</p>
                            <strong>
                                {toLocale(claimableReward)} {coinname}
                            </strong>

                        </div>
                    </div>
                </CCol>
                <CCol className='mb-2'>
                    <div className='inrInfoBx'>
                        <div className='imginrBx'>
                            <img src={ammount} alt='' />
                        </div>
                        <div className="refbox">
                            <p>Staked</p>
                            <strong>
                                {toLocale(stakeAmount)} {coinname}
                            </strong>

                        </div>
                    </div>
                </CCol>

            </CRow>
        </CCol>
    )
}