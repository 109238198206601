import React from "react";
import { CCol, CContainer, CRow } from "@coreui/react";
import icon1 from "../Assets/images/high-return (1).png"
import icon2 from "../Assets/images/no-withdraw-fees (1).png"
import icon3 from "../Assets/images/reduce-penalties (1).png"
import icon4 from "../Assets/images/ref (1).png"
import Banner from "../Components/Banner";
import StakeList from "../Components/StakeList";
import FAQSection from "../Components/Fqsection";




const Home = () => {
  return (
    <>
      <Banner />
      <StakeList />
      <section className="informationMU py-5">
        <CContainer>
            <div className="infotxt">

          <h3>Benefits of Staking ZEB Money:</h3>
            </div>
          <CRow >
            <CCol lg="3" md="6" className="my-2">
              <div className="benefitbox">
                <div className="benefit-icon">
                  <img src={icon1} alt="Safe and Secure" />
                </div>

                <h4>High Returns:</h4>
                <p>
                  Maximize your earnings by staking Zeb Tokens in various pools
                  with competitive interest rates.
                </p>
              </div>
            </CCol>
            <CCol lg="3" md="6" className="my-2">
              <div className="benefitbox">
                <div className="benefit-icon">
                  <img src={icon2} alt="Safe and Secure" />
                </div>

                <h4> No Withdrawal Fees:</h4>
                <p>
                  Unlock your staked tokens without any fees, ensuring you get
                  the full value of your investment.
                </p>
              </div>
            </CCol>
            <CCol lg="3" md="6" className="my-2">
              <div className="benefitbox">
                <div className="benefit-icon">
                  <img src={icon3} alt="Safe and Secure" />
                </div>

                <h4> Reduced Penalties:</h4>
                <p>
                  In case of early withdrawal before the maturity of the 3-month
                  period, a nominal penalty of 10% is applied much lower than
                  industry standards.
                </p>
              </div>
            </CCol>
            <CCol lg="3" md="6" className="my-2">
              <div className="benefitbox">
                <div className="benefit-icon">
                  <img src={icon4} alt="Safe and Secure" />
                </div>

                <h4> Lucrative Referral Program:</h4>
                <p>
                  Benefit from our 3-level referral system, earning up to 1% per
                  month from your first-level referrals, and additional earnings
                  from second and third levels.
                </p>
              </div>
            </CCol>
          </CRow>
        </CContainer>
      </section>
        <FAQSection />
    </>
  );
};

export default Home;
