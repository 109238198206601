import { providers } from "ethers"
import Web3 from "web3"

// COIN  ADDRESS
export const CoinAddress = "0xe8b630986d88653e29622046929aD90C0e3FbdBd";  // ZEB TOKEN  Mainnet
export const USDT = "0xc2132D05D31c914a87C6611C10748AEb04B58e8F"         // USDT TOKEN  Mainnet

// ORACLE & REFERAL ADDRESS
export const OracleAddress = "0xe8669E2988945a0343113c04D17F234feB1BE288"    // Mainnet
export const ReferalContract = "0x51BE106f87cb604d5bA8c4c0E4eE5aE98E9BC243"  // Mainnet


//ZEB POOL
export const PoolZEB90 = "0x5EbB079Ee0Bc2bbaC08Effc1cF2F5B8AAB5C1C41"     // Mainnet
export const PoolZEB180 = "0xE11F52e323a6309A1B33f0cd7C6dE3935a189bA1"    // Mainnet
export const PoolZEB360 = "0x3a6cB7d2DB424Ef45f3d90989649Ec59179c3E84"    // Mainnet

//USDT POOL
export const PoolUSDT180 = "0x2D22973F7d749B34447294427588DA7856796aE4"   // Mainnet
export const PoolUSDT360 = "0xC3A39e76A86bCEe985AA057EE1Cc60509ab124bd"   // Mainnet




export const LockingPeriod = 3 * 86400   // 3 days of Loacking period

// 3 days × 86,400 seconds/day = 259,200 seconds


export const graphQlUrl = "https://api.studio.thegraph.com/query/55529/zebmoney-staking-thegraph/v3"  // Mainnet

export const transactionUrl = "https://polygonscan.com/tx/"


// export const chainRPC = "https://virulent-soft-glade.matic.quiknode.pro/f31fd1dab926f81b6236cd10e84ced895dd6ddf7/"  // Mainnet
export const chainRPC = "https://polygon-rpc.com/"  // Mainnet
export const defaultWeb3 = new Web3(chainRPC)
export const web3Provider = new providers.JsonRpcProvider(chainRPC);

// change the chain to polygon on mainnet shift on index.js page 
export const defaultNetwork = 137


// URL used in project 
export const url = "http://zebmoney.org/"   // Mainnet referral url
export const polygonchainRPC = "https://polygon-rpc.com"



export const poolDetails = [{ pool: PoolZEB90, percent: 25, days: 90, coin: "ZEB" },
{ pool: PoolZEB180, percent: 30, days: 180, coin: "ZEB" },
{ pool: PoolZEB360, percent: 36, days: 360, coin: "ZEB" },
{ pool: PoolUSDT180, percent: 30, days: 180, coin: "USDT" },
{ pool: PoolUSDT360, percent: 36, days: 360, coin: "USDT" },
]



// export const web3Provider = new ethers.providers.JsonRpcProvider(defaultWeb3.currentProvider)


// export const testingContract = "0x84e80354ef08eb2442dA64A8ed957fdA046ad448"

